import React from "react";
import { Link, FormattedMessage } from "gatsby-plugin-intl";

import Layout from "../components/layout/index";
import SEO from "../components/seo";

const TermsPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Terms of Use | GivFast" />
      <div className="relative py-16 overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <article>
            <div className="text-lg max-w-prose mx-auto mb-6 prose">
              <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                <FormattedMessage id="title_terms_page" />
              </h1>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_1" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_2" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_1" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_3" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_4" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_5_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_5_2" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_6_1" />
                </strong>
                <FormattedMessage id="terms_p_6_2" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_7_1" />
                </strong>
                <FormattedMessage id="terms_p_7_2" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_2" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_8" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_9" />
              </p>

              <ul className="text-base">
                <li>
                  <FormattedMessage id="terms_l_1" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_2" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_3" />
                </li>
              </ul>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_10" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_3" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_11" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_12" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_13_1" />
                &nbsp;
                <a href="https://stripe.com/privacy">
                  <FormattedMessage id="terms_p_13_2" />
                </a>
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_4" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_14" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_5" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_15" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_6" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_16_1" />
                &nbsp;&nbsp;
                <a href="mailto:office@givfast.com">office@givfast.com</a>&nbsp;
                <FormattedMessage id="terms_p_16_2" />
                &nbsp;
                <b>
                  <em>
                    <FormattedMessage id="terms_p_16_3" />
                  </em>
                </b>
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_7" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_17" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_18_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_18_2" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_19_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_19_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_20" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_21" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_22" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_23_1" />
                  &nbsp;&nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_23_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_24" />
                &nbsp;
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_25" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_26" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_27_1" />
                </strong>
                &nbsp;&nbsp;
                <FormattedMessage id="terms_p_27_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_28" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_29_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_29_2" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_8" />
                &nbsp;
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_30" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_31" />
              </p>

              <ul className="text-base">
                <li>
                  <FormattedMessage id="terms_l_4" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_5" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_6" />
                </li>
              </ul>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_32" />
              </p>

              <ul className="text-base">
                <li>
                  <FormattedMessage id="terms_l_7" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_8" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_9" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_10" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_11" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_12" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_13" />
                </li>
              </ul>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_33_1" />
                &nbsp;
                <a href="mailto:support@givfast.com">support@givfast.com</a>
                .&nbsp;&nbsp;
                <FormattedMessage id="terms_p_33_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_34" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_9" />
              </h2>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_10" />
                &nbsp;
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_35" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_36" />
                &nbsp;
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_37" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_11" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_38" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_39_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_39_2" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_40_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_40_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_41" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_12" />
                &nbsp;
              </h2>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_42_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_42_2" />
                &nbsp;
                <strong>
                  <FormattedMessage id="terms_p_42_3" />
                </strong>
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_43" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_44_1" />
                </strong>
                &nbsp;&nbsp;
                <FormattedMessage id="terms_p_44_2" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_13" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_45" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_46_1" />
                &nbsp;
                <a href="mailto:support@givfast.com">
                  <FormattedMessage id="terms_p_46_2" />
                </a>
                &nbsp;
                <FormattedMessage id="terms_p_46_3" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_47_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_47_2" /> Christian Conciliation of
                the Institute for Christian Conciliation<sup>TM</sup>
                <FormattedMessage id="terms_p_47_3" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_48_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_48_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_49" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_50_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_50_2" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_14" />
                &nbsp;
              </h2>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_51_1" />
                  &nbsp;
                </strong>
                <FormattedMessage id="terms_p_51_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_52" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_53" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_54" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_55" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_56" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_57" />
              </p>

              <ul className="text-base">
                <li>
                  <FormattedMessage id="terms_l_14" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_15" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_16" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_17" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_18" />
                </li>
              </ul>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_58_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_58_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_59" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_60" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_61" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_62" />
                &nbsp;
                <a href="mailto:office@givfast.com">office @givfast.com.</a>
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_15" />
                &nbsp;
              </h2>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_63_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_63_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_64" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_65" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_66_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_66_2" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_67_1" />
                </strong>
                :&nbsp;&nbsp;
                <FormattedMessage id="terms_p_67_2" />
                <Link to="/privacy-policy">
                  &nbsp;
                  <FormattedMessage id="terms_p_67_3" />
                </Link>
                &nbsp;
                <FormattedMessage id="terms_p_67_4" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_68" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_69" />
                &nbsp;
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_70_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_70_2" />
                &nbsp;
                <a href="mailto:support@givfast.com">support@givfast.com</a>.
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_71" />
              </p>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_16" />
                &nbsp;
              </h2>

              <h2 className="my-4 font-bold">
                <FormattedMessage id="terms_h_17" />
              </h2>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_72" />
              </p>

              <ul className="text-base">
                <li>
                  <FormattedMessage id="terms_l_19" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_20" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_21" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_22" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_23" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_24" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_25" />
                </li>
                <li>
                  <FormattedMessage id="terms_l_26_1" />
                  &nbsp;
                  <a href="https://www.consumer.ftc.gov/features/scam-alerts">
                    <FormattedMessage id="terms_l_26_2" />
                  </a>
                  &nbsp;
                  <FormattedMessage id="terms_l_26_3" />
                  &nbsp;
                  <a href="https://www.ftccomplaintassistant.gov/">
                    <FormattedMessage id="terms_l_26_4" />
                  </a>
                  .
                </li>
              </ul>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_73_1" />
                </strong>
                &nbsp;&nbsp;
                <FormattedMessage id="terms_p_73_2" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_74" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_75" />
                &nbsp;
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_76_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_76_1" />
              </p>

              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_77" />
              </p>

              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_78_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_78_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_79_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_79_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_80_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_80_2" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_81" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_82" />
              </p>
              <p className="my-2 text-base">
                <FormattedMessage id="terms_p_83" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_84_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_84_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_85_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_85_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_86_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_86_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_87_1" />
                  &nbsp;
                </strong>
                <FormattedMessage id="terms_p_87_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_88_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_88_2" />
              </p>
              <p className="my-2 text-base">
                <strong>
                  <FormattedMessage id="terms_p_89_1" />
                  &nbsp;&nbsp;
                </strong>
                <FormattedMessage id="terms_p_89_2" />
              </p>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default TermsPage;
